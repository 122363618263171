body {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100vh;
    width: 100vw;
    margin: 0;
    overflow-x: hidden;
    overflow-y: auto;

    --bg-main-color: #3D4047;
    --main-color: #2f7ed4;
    font-family: Segoe UI, \6e38\30b4\30b7\30c3\30af Medium, \6e38\30b4\30b7\30c3\30af\4f53, Yu Gothic Medium, YuGothic, sans-serif !important;
    color: var(--main-color);
    font-weight: 600;
    font-size: x-large !important;
}

.material-icons {
    font-family: 'Material Icons' !important;
    font-size: 28px !important;
    width: fit-content !important;
    height: fit-content !important;
    text-align: center !important;
}

button {
    /* Reset default button styles */
    border: none;
    margin: 0;
    padding: 0;
    width: auto;
    overflow: visible;
    background: transparent;
    color: var(--bg-main-color);
    font: inherit;
    line-height: normal;
}

.amplify-loader {
    --amplify-components-loader-stroke-filled-color: var(--main-color);
}

.loading-indicator {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.5);
    /* Semi-transparent white background */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
}

.overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    /* height: 100%; */
    background-color: rgba(255, 255, 255, 0.5);
    /* Semi-transparent white background */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
}

.toaster {
    position: sticky;
    background-color: #4F8051;
    color: #fff;
    text-align: center;
    padding: 10px;
    border-radius: 4px;
    height: 0;
    opacity: 0;
    visibility: hidden;
    transition: opacity 1s ease-in-out, visibility 1s;

    &.over {
        position: absolute;
        z-index: 1;
    }
}

.over.toaster

.toaster.show {
    height: auto;
    opacity: 1;
    visibility: visible;
}

.toaster.hide {
    height: 0;
    opacity: 0;
    visibility: hidden;
}

.toaster.error {
    background-color: rgba(180, 64, 64, 0.750);
}


.fade.enter{
    opacity: 0;
}
.fade.exit{
    opacity: 1;
}
.fade.enter-active{
    opacity: 1;
}
.fade.exit-active{
    opacity: 0;
}
.fade.enter-active,
.fade.exit-active{
    transition: opacity 500ms;
}
