.camera-container {
  background-color: #b6b5b55e;
  position: relative;
  min-height: 400px;
  max-height: 100vh;
}

.camera-preview {
  margin: auto;
  /* object-fit: cover; */
}

.camera-overlay {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(255,255,255,.65);
  display: flex;
  align-items: center;
  justify-content: center;
}

button {
  border-radius: 8px;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  font-size: 18px;
  max-width: 20ch;
  box-shadow: 0 0 4px 0 #00000080;
  cursor: pointer;
  background-color: var(--main-color);
  color: #fff;
  width: 20ch !important;
  border: none !important;
  line-height: 1.5;
  padding: 8px;
}

#controls {
  margin-top: .5em;
  >div {
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    flex-direction: row;
    gap: 1rem;

    flex: 1;
  }
}
